import React, {useEffect, useState, useContext} from 'react';
import { Button, Dropdown, Table, ButtonGroup, Modal, closeButton } from 'react-bootstrap';
//import { faEye, faEllipsisH, faCheck, faCancel } from "@fortawesome/free-solid-svg-icons";
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';
import { useSnackbar } from 'notistack';
import Preloader from '../../components/Preloader/Preloader';
import { ProductContext } from '../../ProductProvider';
import RegistrationForm from './RegistrationForm';
import AcademicInfo from './AcademicInfo';
import FileAttachments from './FileAttachments';
import ReactToPrint from "react-to-print";

const config = require('../../config.json');

export default function ListRegistrationStatus(props) {
  const {applicationDetails, setApplicationDetails, status} = props;
  const { loggedUser, alertClose, alertDetails, setAlertDetails, generateDate, handleImageError } = useContext(ProductContext);
  const [loaded, setLoaded] = useState(false);
  const componentRef = React.useRef(null);

  //const [selectedItem, setSelectedItem]=useState('');
//  const [alertDetails, setAlertDetails] = useState({style:"", open:false, message:""});
const [collapsed, setCollapsed] = useState(false);
const [profileDetails, setProfileDetails]=useState({title:'Mr',firstName:'', middleName:'', lastName:'', dateOfBirth:'', gender:'Male', maritialStatus:'Married',
  occupation:'', religion:'Christian', denomination:'', diocese:'', areaCode:'',mobileNumber:'', emailID:'', landlineNumber:'', addressStreet:'', addressState:'',
  addressPincode:'', nationality:'', motherTongue:'', englishProficiency:'Excellent',degreeDetails:[]});
const [certProofFile, setCertProofFile]=useState('');
const [markListFile, setMarkListFile]=useState('');
const [recommenLetterFile, setRecommenLetterFile]=useState('');
const { enqueueSnackbar } = useSnackbar();

useEffect(()=>{
  //console.log("props : " , props);
  alertClose();
  fetchApplicationDetails();
},[])

const toggle = () => {
  //   console.log("clicked");
  
       setCollapsed(!collapsed);
   }

const fetchApplicationDetails=async()=>{
  try{
      setLoaded(false);
      //const session = await Auth.currentSession();
      //const access_token=session.idToken.jwtToken;
      const { idToken } = (await fetchAuthSession()).tokens ?? {};
      const clientID="VJCollege";
      const applicationStatus=status;
      await axios.get(`${config.api.invokeURL}/dashboard/registration?clientID=${clientID}&applicationStatus=${applicationStatus}`,{
          headers: {
            Authorization: idToken,
            'x-api-key': config.api.key
          }}, 
      ).then((response)=>{
          setApplicationDetails(response.data);
          setAlertDetails({
              ...alertDetails,
              style:"success",
              open:true,
              message: response.data.length + " - " + status
          });  
          //console.log(response);
          setLoaded(true);
      }).catch((error)=>{
          //console.log(error);
          enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
          setLoaded(true);
          //setDispMsg(error.message);
      })
  
  }catch(error){
    enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
      setLoaded(true);
  }
}

const handleApplicationView=async(event, item)=>{
  event.preventDefault();
//   console.log("Item : ", item);
  try{
    const titem=item;
    if(titem.degreeDetails===undefined){
      titem.degreeDetail=[];
    }
    if(titem.recommLetter===undefined){
      titem.recommLetter="";
    }
    if(titem.certProofFile===undefined){
      titem.certProofFile="";
    }
    if(titem.avatar===undefined){
      titem.avatar="";
    }
    //console.log("TITEM : " , item);
    setProfileDetails(titem);
    const tapplicationNo= item.applicationNo   
    if(item.recommLetter===""||item.recommLetter===undefined||item.recommLetter===null){
      setRecommenLetterFile('');
    }else{
      const tIDProofName=item.recommLetter;
      await setRecommenLetterFile(`${config.s3.profileUrl}${tapplicationNo}${"/docs/"}${tIDProofName}`);
    }
    if(item.certproofFile===""||item.certproofFile===undefined||item.certproofFile===null){
      setCertProofFile('');
    }else{
   //  const tcertProofName=tapplicationNo+"_certproof.jpeg";
    const tcertProofName=item.certproofFile;
    await setCertProofFile(`${config.s3.profileUrl}${tapplicationNo}${"/docs/"}${tcertProofName}`);
    }
    if(item.markListFile===""||item.markListFile===undefined||item.markListFile===null){
      setMarkListFile('');
    }else{
      const tIDProofName=item.markListFile;
      await setMarkListFile(`${config.s3.profileUrl}${tapplicationNo}${"/docs/"}${tIDProofName}`);
    }

      toggle();
  }catch(error){
    enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
  }
}

const handleApplicationUpdate=async(event, item, tStatus)=>{
  event.preventDefault();
 console.log("Item : ", item);
  try{
    setLoaded(false);
      const tDate= await generateDate();
      var tcoursePayment="";
      if(tStatus==="Approved"){
        tcoursePayment="Pending";
      }else{
        tcoursePayment="Declined";
      }
    //const session = await Auth.currentSession();
   //const access_token=session.idToken.jwtToken;
   const { idToken } = (await fetchAuthSession()).tokens ?? {};
   const {clientID, applicationNo, applicationDate, applicantName, fatherName, dateOfBirth, gender, birthDistrict, birthState, religiousStatus, parishDiocese, 
    congregationProvince, areaCode, mobileNumber, emailID, placeOfBirth, localAddress, localState, 
    localPincode, nationality, localContact, perAddress, perState, perPincode, perContactNo, courseCategory,
    languageDetails, degreeDetails, avatar, recommLetter, markListFile, certproofFile, courseDetails }=item;  

   const params={
    clientID:clientID, applicationNo: applicationNo, applicationDate: applicationDate, applicationStatus:tStatus,
    applicantName:applicantName,fatherName:fatherName, birthDistrict:birthDistrict, birthState:birthState, dateOfBirth:dateOfBirth, 
    gender:gender, religiousStatus:religiousStatus, parishDiocese:parishDiocese, congregationProvince:congregationProvince, 
    placeOfBirth:placeOfBirth, localAddress:localAddress, areaCode:areaCode, mobileNumber:mobileNumber, emailID:emailID, 
    localState:localState, localPincode:localPincode, localContact:localContact, perAddress:perAddress, nationality:nationality, 
    perState:perState, perPincode:perPincode, perContactNo:perContactNo, avatar:avatar, recommLetter:recommLetter, markListFile:markListFile, certproofFile:certproofFile,
    courseStatus:tStatus, coursePayment:tcoursePayment, courseCategory:courseCategory, degreeDetails:degreeDetails, languageDetails:languageDetails, updatedBy:loggedUser.name,
    updatedOn:tDate, courseDetails:courseDetails};

    console.log("Params :", params);

    await axios.patch(`${config.api.invokeURL}/dashboard/registration`, params,{
          headers: {
            Authorization: idToken,
            'x-api-key':config.api.key
          }})
          .then(async(response)=>{
            enqueueSnackbar("Successfully Updated",{variant:'success'}); 
            fetchApplicationDetails();
            setLoaded(true);            
          })
          .catch((error)=>{
            enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
            setLoaded(true);
          })
    
  }catch(error){
    enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
      setLoaded(true);
  }
}

const reactToPrintContent = React.useCallback(() => {
  return componentRef.current;
}, [componentRef.current]);

const reactToPrintTrigger = React.useCallback(() => {
  return <Button variant="outline-primary"  size="sm">Print</Button>;
}, []);

  return (
    <div>
      {!loaded && <Preloader show={loaded ? false : true} />}
       {/*View*/}
       <Modal show={collapsed} onHide={toggle} size="lg" >
        <Modal.Header closeButton>
          <Modal.Title>View Application Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modalStyle'>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
      <div className="d-block mb-4 mb-md-0">
      </div>
      <div className="btn-toolbar mb-2 mb-md-0"> 
        <ButtonGroup>
        <ReactToPrint
      content={reactToPrintContent}
      documentTitle="student"
      trigger={reactToPrintTrigger}
    />
        {/*<Button variant="outline-primary" size="sm" onClick={(event)=>{handleDownload(event)}}>Download</Button>
        <CSVLink
            headers={downloadHeaders}
            filename="download.csv"
            data={searchResults}
            ref={csvLinkEl}
  />*/}
        </ButtonGroup>
      </div>
    </div>
          <div ref={componentRef} style={{width:"100%"}} >
          <RegistrationForm formType={"View"} profileDetails={profileDetails} setProfileDetails={setProfileDetails}/> 
          <AcademicInfo formType={"View"} form={profileDetails} setForm={setProfileDetails} />  
          <FileAttachments certProofFile={certProofFile} markListFile={markListFile} recommenLetterFile={recommenLetterFile}/>    
          </div>
        </Modal.Body>
        <Modal.Footer>
        <div style={{display:"flex", justifyContent:"end"}}>
          <Button onClick={toggle}>
                Close
          </Button>
          </div>
        </Modal.Footer>
    </Modal>
       <Table responsive hover className="user-table align-items-center">
             <thead className='theadStyle'>
                <tr>
                <th>#</th>
                <th>Application No</th>
                {status==="Approved"?<th>Registration No</th>:null}
                <th>Name</th>
                <th>Phone</th>
                <th>Date Applied</th>
                <th>Course</th>
               {status==="Pending Approval"||status==="Approved"? <th>Action</th>:null}
                </tr>
            </thead>
            <tbody className='tbodyStyle'>
            {applicationDetails.map((item, index)=>
            <tr key={index} className='trStyle'>
            <th scope="row">{index+1}</th>
            <td>{item.applicationNo}</td>
            {status==="Approved"?<td>{item.registrationNo}</td>:null}
            <td><img id="profile" src={`${config.s3.profileUrl}${item.applicationNo}${"/"}${item.avatar}`} 
           onError={handleImageError}
           alt="Profile" style={{width:"3rem", height:"3rem", borderRadius:"50%"}} /> {item.applicantName}</td>
            <td>{item.areaCode}-{item.mobileNumber}</td>
            <td>{item.applicationDate}</td>
            <td>{item.courseCategory}</td>
           {status==="Pending Approval"? <td>
           <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
              <i className="fa fa-ellipsis-h icon-dark" aria-hidden="true"></i>
               {/*} <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />*/}
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item   onClick={(event)=>{handleApplicationView(event, item)}}>
               {/*} <FontAwesomeIcon icon={faEye} className="me-2" /> */}
                <i className="fa fa-eye" aria-hidden="true">View Details</i>
              </Dropdown.Item>
              <Dropdown.Item className="text-success" onClick={(event)=>{handleApplicationUpdate(event, item,"Approved")}}>
               {/*} <FontAwesomeIcon icon={faCheck} className="me-2" /> */}
                <i className="fa fa-check me-2" aria-hidden="true">Approve</i>
              </Dropdown.Item>
              <Dropdown.Item className="text-danger" onClick={(event)=>{handleApplicationUpdate(event, item,"Declined")}}>
               {/*} <FontAwesomeIcon icon={faCancel} className="me-2" /> */}
                <i className="fa fa-times me-2" aria-hidden="true">Deny</i>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
            {/*<FontAwesomeIcon icon={faEye} className="me-2" onClick={(event)=>{handleApplicationView(event, item)}}/>
            <FontAwesomeIcon icon={faCheck} className="me-2" onClick={(event)=>{handleApplicationUpdate(event, item,"Approved")}}/>
            <FontAwesomeIcon icon={faCancel} className="me-2" onClick={(event)=>{handleApplicationUpdate(event, item,"Declined")}}/>
            
            <button onClick={(event)=>{handleProjectView(event, item)}}>View</button>
            <button onClick={(event)=>{handleProjectDelete(event, item)}}>Delete</button>
            <button onClick={(event)=>{handleProjectEdit(event, item)}}>Edit</button>*/}
            </td>:status==="Approved"? <td>
           <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
              <i className="fa fa-ellipsis-h icon-dark" aria-hidden="true"></i>

{/*}                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />*/}
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item  onClick={(event)=>{handleApplicationView(event, item)}}>
                {/*<FontAwesomeIcon icon={faEye} className="me-2" />*/}
                <i className="fa fa-eye me-2" aria-hidden="true"> View Details </i>
              </Dropdown.Item>
             {/*} <Dropdown.Item className="text-success" onClick={(event)=>{handleApplicationUpdate(event, item,"Approved")}}>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item className="text-danger" onClick={(event)=>{handleApplicationUpdate(event, item,"Declined")}}>
                <FontAwesomeIcon icon={faCancel} className="me-2" /> Deny
          </Dropdown.Item>*/}
            </Dropdown.Menu>
          </Dropdown>
            </td>:null}
            </tr>)}
            </tbody>
            </Table>
    </div>
  )
}

import React, { useEffect, useContext, useState } from 'react';
import {Button, Row, Col, Form} from 'react-bootstrap';
import axios from "axios";
import { useSnackbar } from 'notistack';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faEye, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { ProductContext } from '../../ProductProvider';
import Header from "../../components/header/Header";
import Preloader from '../../components/Preloader/Preloader';
//import PageLoader from '../../components/Preloader/PageLoader';
import { fetchAuthSession } from 'aws-amplify/auth';

const config = require('../../config.json');

export default function AddPublications(props) {
    const { validateLogin, loggedUser, generateDate} = useContext(ProductContext);
    const {formType, selectedItem, setSelectedItem, handleCloseForm} = props;
    const inputRef = React.useRef();
    const [viewOnly, setViewOnly] = useState(true);
    const [titleMsg, setTitleMsg] = useState('');
    const [subTitleMsg, setSubTitleMsg] = useState('');
    const [loaded, setLoaded] = useState(true);
    const [pdfFile, setPDFFile]=useState(null);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
      if(loggedUser.isAuthenticated){
        //console.log("Props : ", props);
        if(formType==="New"){
            resetValues();
            setViewOnly(false);
        }else if (formType==="Edit"){
            setViewOnly(false);
        }else{
            setViewOnly(true);
        }

      }else{
        validateLogin();
      }
    }, [loggedUser])

    const generateSubscriberID=()=>{
        const current_datetime = new Date();
        //const fName=profileDetails.firstName.trim().replace(/\s/g, "");
        //const tfName= fName.substring(0,2).toUpperCase();
        //const lName=profileDetails.lastName.trim().replace(/\s/g, "");
        //const tlName= lName.substring(0,2).toUpperCase();
        var tempUserId="";
        const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
        var date_month = (current_datetime.getMonth() + 1).toString();
        var month_value="";
        date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
        var date_date = (current_datetime.getDate().toString());
        var date_value="";
        date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
        var date_hours = (current_datetime.getHours().toString());
        var hours_value="";
        date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
        var date_minutes = (current_datetime.getMinutes().toString());
        var minutes_value="";
        date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
        const accountNo = tempUserId.concat(date_year,month_value, date_value, hours_value, minutes_value);
        return accountNo;
    }

    const validateFields=(mode)=>{
        const {issue, issueMonth, issueYear, title,authorName, pubCategory,pubStatus}=selectedItem;      
            var returnValue="passed";
            if(mode==="Add" && !pdfFile){
                enqueueSnackbar("Attach the Publication PDF file ",{variant:'info'})
                returnValue="failed";
          }else if(title===""){
            enqueueSnackbar("Title cannot be Empty",{variant:'info'})
            returnValue="failed";
        }else  if(issue===""){
        enqueueSnackbar("Issue cannot be Empty",{variant:'info'})
        returnValue="failed";
      }else  if(issueMonth===""){
        enqueueSnackbar("Issue Month cannot be Empty",{variant:'info'})
        returnValue="failed";
      }else  if(issueYear===""){
        enqueueSnackbar("Issue Year cannot be Empty",{variant:'info'})
        returnValue="failed";
      }else {
        returnValue="passed";
      }
      window.scrollTo(0,0);
      return returnValue;
      }

      const handleFileSelection = (event)=>{
        setLoaded(false);
          event.preventDefault();
          //console.log("selected file : ", event);
          if (event.target.files && event.target.files.length > 0) {
            setPDFFile(event.target.files[0]);
            //console.log("selected file : ", event.target.files[0].name);
            //var tprofile1 = document.getElementById("img1");
            //console.log("Tprofile1 : ", tprofile1);
            //tprofile1.src=URL.createObjectURL(event.target.files[0]);
            /*setAlertDetails({
             ...alertDetails,
             style:"info",
             open:true,
             message:"File Selected - "+ event.target.files[0].name
         }); */  
        }
        setLoaded(true);
       }

    const handleSavePublications=async(event)=>{
        event.preventDefault();
        try{
        const validationResult=validateFields("Add");
        if(validationResult==="passed"){
          setLoaded(false);
          const {id, issue,title, issueYear,issueMonth, pubStatus, description, pubCategory, pubPrice, intlPrice, pages, publisherName,regDate, authorName }=selectedItem;
          const pubID= await generateSubscriberID();
          const todayDate=await generateDate();
          //const session = await Auth.currentSession();
          //const access_token=session.idToken.jwtToken;
          const { idToken } = (await fetchAuthSession()).tokens ?? {};
          const params={
            id: pubID, 
            title:title,
            issue:issue, 
            issueMonth:issueMonth, 
            issueYear:issueYear,
            pubStatus:pubStatus,
            description:description,
            pubCategory:pubCategory,
            pubPrice:pubPrice,
            intlPrice:intlPrice,
            pages:pages,
            regDate:regDate,
            authorName:authorName,
            publisherName:publisherName,
            updatedBy:loggedUser.name, 
            updatedOn:todayDate
          };
          //console.log("Params : ", params);
          await axios.post(`${config.api.invokeURL}/dashboard/publications`, params,{
            headers: {
              Authorization: idToken,
              'x-api-key':config.api.key
            }})
            .then(async(response)=>{
              await postPDFOnS3(pubID, pdfFile);
              //await postImageOnS3(journalid, posterFile);
              enqueueSnackbar("Publication successfully added",{variant:'success'})
            resetValues();
            setLoaded(true);
            }).catch((error)=>{
                //console.log("Error :", error);
                enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'})
                setLoaded(true);
            })
            setLoaded(true);
        }
          }catch(error){
            //console.log("Error :", error);
            enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'})
            setLoaded(true);
        }
      }

      const handleUpdatePublications=async(event)=>{
        event.preventDefault();
        try{
        const validationResult=validateFields("Edit");
        if(validationResult==="passed"){
          setLoaded(false);
          const {id, issue,title, issueYear,issueMonth, pubStatus, description, pubCategory, pubPrice, intlPrice, pages, publisherName,regDate, authorName }=selectedItem;
          //const pubID= await generateSubscriberID();
          const todayDate=await generateDate();
          //const session = await Auth.currentSession();
          //const access_token=session.idToken.jwtToken;
          const params={
            id: id, 
            title:title,
            issue:issue, 
            issueMonth:issueMonth, 
            issueYear:issueYear,
            pubStatus:pubStatus,
            description:description,
            pubCategory:pubCategory,
            pubPrice:pubPrice,
            intlPrice:intlPrice,
            pages:pages,
            regDate:regDate,
            authorName:authorName,
            publisherName:publisherName,
            updatedBy:loggedUser.name, 
            updatedOn:todayDate
          };
          //console.log("Params : ", params);
          await axios.patch(`${config.api.invokeURL}/dashboard/publications`, params,{
            headers: {
             // Authorization: access_token,
              'x-api-key':config.api.key
            }})
            .then(async(response)=>{
                if(pdfFile){
                    await postPDFOnS3(id, pdfFile);
                }
              //await postImageOnS3(journalid, posterFile);
              enqueueSnackbar("Publication successfully added",{variant:'success'})
            resetValues();
            setLoaded(true);
            }).catch((error)=>{
                //console.log("Error :", error);
                enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'})
                setLoaded(true);
            })
            setLoaded(true);
        }
          }catch(error){
            //console.log("Error :", error);
            enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'})
            setLoaded(true);
        }
      }

      const postPDFOnS3 = async(pdfFileName,timageFile)=>{
        try{
            //  console.log("NewImage : ", newImage);
            var keyName="pdfs/vjtoday/";
            var newFileName=pdfFileName+".pdf";      
          //console.log("newfilename :", newFileName);
         // const newImage="";
          var file = timageFile;
          var blob = file.slice(0, file.size, 'application/pdf'); 
          const newFile = new File([blob], newFileName, {type: 'application/pdf'});
         // console.log("New file : ", newFile);
          //const session = await Auth.currentSession();
          //const access_token=session.idToken.jwtToken;
          const { idToken } = (await fetchAuthSession()).tokens ?? {};

          const bucketName=config.s3.pdfbucketName;
          //console.log("Bucket Name : ", bucketName);
         // await axios.get(`${config.api.invokeUrl}/profile_avatar/presigned/uploader?fileName=${newFileName}&bucketName=${bucketName}`,{
         // await axios.get(`${config.api.invokeURL}/images/gallery/newgallery?fileName=${newFileName}&bucketName=${bucketName}&keyName=${newKeyName}`,{
          const fileType="application/pdf";
          await axios.get(`${config.api.invokeURL}/presigned/uploader?fileName=${newFileName}&bucketName=${bucketName}&keyName=${keyName}&fileType=${fileType}`,{
               headers:{
                   Authorization: idToken,
                   'x-api-key': config.api.key
               }
           }).then(async(response)=>{
               //console.log("Response : ", response);
              const res=await fetch(response.data.uploadURL, { method: 'PUT',
               headers:{
                   "Content-Type":"application/pdf"
                 },body: newFile});
               //  console.log("Res : ", res);
                 if(res.status===200){
                    enqueueSnackbar("PDF successfully uploaded",{variant:'success'})
                //  setNewProfileImage(newFileName);
                  //  fetchGalleryImages();
                    // setProfileImage(newFileName);
                     //var timestamp = new Date().getTime();     
                    // var tprofile = document.getElementById("profile");
                     //var imgsrc="";
                     //imgsrc=imgsrc.concat(config.s3.imageURL,newFileName,"?t=",timestamp);     
                     //tprofile.src=`${config.s3.imageURL}${newFileName}`+"?t="+timestamp;
                    // tprofile.src=imgsrc;
                    //tprofile.src="https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg";
                     //console.log("tprofle : ", tprofile.src);
                 }
            }).catch((error)=>{
               enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'})
           })
        }catch(error){
            enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'})
        }
      }

      const resetValues=()=>{
        const todayDate= generateDate();
        setSelectedItem({...selectedItem, id:'', issue:'', title:'',issueYear:'', issueMonth:'',pubStatus:'Active', description:'', pubCategory:'VJ Today', pubPrice:"0", intlPrice:"0", pages:"0", publisherName:'', ratings:'', regDate:todayDate, startDate:'', endDate:'', authorName:''});
      }
    
  
  return (
    <div m="20px">
    {!loaded && <Preloader show={loaded ? false : true} />}
    <Header title={titleMsg} subtitle={subTitleMsg} />
          <div
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          >
<Form>
      <Form.Group as={Row} className="mb-3" controlId="formPlaintextTitle">
      <Form.Label column sm="4">
      Publication
        </Form.Label>
        <Col sm="8">
        <Form.Select size="lg" style={{fontSize:16, borderRadius:10, padding:8}}
                id="title"
                value={selectedItem.title}
                disabled={viewOnly}
                onChange={(event)=>{setSelectedItem({...selectedItem, title:event.target.value})}}>               
                <option value="VJ Today">VJ Today</option>
                <option value="AVE Reflection">AVE Reflection</option>
                <option value="Tattvaviveka">Tattvaviveka</option>
        </Form.Select>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formPlaintextIssue">
        <Form.Label column sm="4">
          Issue Title
        </Form.Label>
        <Col sm="8">
        <Form.Control
                type="text"
                name="issue"
                value={selectedItem.issue}
                disabled={viewOnly}
                onChange={(event)=>{setSelectedItem({...selectedItem, issue:event.target.value})}}  
                />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formPlaintextIssueYear">
        <Form.Label column sm="4">
          Issue Year
        </Form.Label>
        <Col sm="8">
        <Form.Control
                type="text"
                value={selectedItem.issueYear}
                name="issueyear"
                disabled={viewOnly}
                onChange={(event)=>{setSelectedItem({...selectedItem, issueYear:event.target.value})}}  
                  />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formPlaintextIssueMonth">
        <Form.Label column sm="4">
          Issue Month
        </Form.Label>
        <Col sm="8">
        <Form.Control
                type="text"
                name="issueMonth"
                value={selectedItem.issueMonth}
                disabled={viewOnly}
                onChange={(event)=>{setSelectedItem({...selectedItem, issueMonth:event.target.value})}}
                    />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formPlaintextRegisterDate">
        <Form.Label column sm="4">
          Date Registered
        </Form.Label>
        <Col sm="8">
        <Form.Control
                type="date"
                name="dateregistered"
                value={selectedItem.regDate}
                onChange={(event)=>{setSelectedItem({...selectedItem, regDate:event.target.value})}}
                disabled={viewOnly}
                      />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formPlaintextStatus">
      <Form.Label column sm="4">
      Status
        </Form.Label>
        <Col sm="8">
        <Form.Select size="lg" style={{fontSize:16, borderRadius:10, padding:8}}
                id="status"
                value={selectedItem.pubStatusStatus}
                disabled={viewOnly}
                onChange={(event)=>{setSelectedItem({...selectedItem, pubStatus:event.target.value})}}                >
                <option value="Active">Active</option>
                <option value="Archive">Archive</option>
                <option value="In-Active">In-Active</option>
                    </Form.Select>
        </Col>
      </Form.Group>

            {!viewOnly && <>
             <Form.Control        
                className=""
                type="file"
                accept="application/pdf"
                ref={inputRef}
                onChange={handleFileSelection}  
             />
            </>}
          </Form>
          </div>
          <div style={{justifyItems:"center", textAlign:"center", padding:"20px"}} >
            {formType==="New"?<Button type="submit" color="secondary" 
            onClick={event=>handleSavePublications(event)}>
              Save
            </Button>:formType==="Edit"?<Button type="submit" color="secondary" 
            onClick={event=>handleUpdatePublications(event)}>
              Update
            </Button>:null} {"        "}
            <Button type="submit" color="secondary" onClick={event=>handleCloseForm(event)}>
              Cancel
            </Button>
          </div>
  </div>
  )
}

import React,{useState, useEffect, useContext} from 'react';
// Import the main component
import { ProgressBar, Viewer } from '@react-pdf-viewer/core'; // install this library
// Plugins
//import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'; // install this library
//import { searchPlugin } from '@react-pdf-viewer/search';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar'; //install this library
//import { ToolbarSlot, TransformToolbarSlot } from '@react-pdf-viewer/toolbar';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
//import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
// Worker
import { Worker } from '@react-pdf-viewer/core'; // install this library
//import { pdfJournalData } from '../../ProData';
//import axios from 'axios';
import { ProductContext } from '../../ProductProvider';
import packageJson from '../../../package.json';
//import { Auth } from "aws-amplify";
//import pope from '../../assets/img/popefrancis.jpg';
const config = require('../../config.json');

export const ViewPDF = (props) => {
  // for onchange event
  const {pdfFile, handleClosePDF } = props;
  const { alertClose, alertDetails, setAlertDetails, pdfFormType, setPDFFormType, setPDFDetails, loggedUser, validateLogin } = useContext(ProductContext);
  //const [showPDF, setShowPDF]=useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [searchTopic, setSearchTopic] = useState('JournalID');
  const [cardView, setCardView] = useState(true); 
  // Create new plugin instance
  //const defaultLayoutPluginInstance = defaultLayoutPlugin();
  //const searchPluginInstance = searchPlugin();
  const toolbarPluginInstance = toolbarPlugin();
  const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;
  const componentRef = React.useRef(null);
  const pdfjsVersion = packageJson.dependencies['pdfjs-dist'];

  useEffect(()=>{
    if(loggedUser.isAuthenticated){
      alertClose();
      //processBatchYear();
    }else{
    validateLogin();
    alertClose();
    }
    return()=>{
      alertClose();
    }
   // console.log(batchYear);
  },[loggedUser])

  const toggle = () => {
    //   console.log("clicked");
         setCollapsed(!collapsed);
  }
  
  const transform = (slot) => ({
      ...slot,
      Download: () => <></>,
      DownloadMenuItem: () => <></>,
      Open: () => <></>,
      OpenMenuItem: () => <></>,
      Print: () => <></>,
      PrintMenuItem: () => <></>,
      //EnterFullScreen: () => <></>,
      //EnterFullScreenMenuItem: () => <></>,
      SwitchTheme: () => <></>,
      SwitchThemeMenuItem: () => <></>,
  });

{/*const handleFetchPDF = async(e, item) => {
    e.preventDefault();
    //console.log("fetch : ", item);
    try{
      const fetchFile=item.journalID+".pdf";
      //console.log("fetch file :", fetchFile);
      const bucketName='docs.vidyajyotijournal.com/pdfs';
      //const session = await Auth.currentSession();
      //const access_token=session.idToken.jwtToken;
      await axios.get(`${config.api.invokeUrl}/presigned/static?fileName=${fetchFile}&bucketName=${bucketName}`,{
        headers: {
          //Authorization: access_token,
          'x-api-key': config.api.key
        }}, 
     ).then(async(response)=>{
          //console.log("Response : ", response);
          setPdfFile(response.data.downloadURL);
          setShowPDF(true); 

      }).catch((error)=>{
          console.log("Error1 : ", error);
          //setDispMsg("Error Occured - ", error.message);
      })        
    }catch(error){
        console.log("Error2 : ", error);
    }
}*/}

/*const handleBack=(e)=>{
  e.preventDefault();
  setPdfFile('');
  setShowPDF(false); 
}*/

const handlePageChange = (e) => {
  localStorage.setItem('current-page', `${e.currentPage}`);
};

const handleSwitchTheme = (theme) => {
  localStorage.setItem('current-theme', theme);
};

const handleEditPDF=(e, item,action)=>{
e.preventDefault();
try{
  setPDFFormType(action);
  setPDFDetails(item);
  toggle();
}catch(error){
  setAlertDetails({
    ...alertDetails,
    style:"warning",
    open:true,
    message:"Error Occured - " + error.message
});        
}
}

const handleSearchSelection=(event)=>{
  setSearchTopic(event.target.value);
  if(event.target.value==="JournalStatus"){
    setSearchValue("Active");
  }else{
    setSearchValue('');
  }
}

const handleLayoutChange=(event, mode)=>{
  event.preventDefault();
  if(mode==="List"){
    setCardView(false);
  }else{
    setCardView(true);
  }
}

const renderError = (error) => {
    let message = '';
    switch (error.name) {
        case 'InvalidPDFException':
            message = 'The document is invalid or corrupted';
            break;
        case 'MissingPDFException':
            message = 'The document is missing';
            break;
        case 'UnexpectedResponseException':
            message = 'Unexpected server response';
            break;
        default:
            message = 'Cannot load the document';
            break;
    }

    return (
        <div
            style={{
                alignItems: 'center',
                display: 'flex',
                height: '100%',
                justifyContent: 'center',
            }}
        >
            <div
                style={{
                    backgroundColor: '#e53e3e',
                    borderRadius: '0.25rem',
                    color: '#fff',
                    padding: '0.5rem',
                }}
            >
                {message}
            </div>
        </div>
    );
};

return (
<div>     
    <div style={{height:800}}>
      <div>
      </div>
       {/*} <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}>*/}
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">

        <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
          <Viewer fileUrl={pdfFile}  onPageChange={handlePageChange} plugins={[toolbarPluginInstance]} theme="auto"
          onSwitchTheme={handleSwitchTheme} renderError={renderError}
          renderLoader={(percentages) => (
            <div style={{ width: '240px' }}>
                <ProgressBar progress={Math.round(percentages)} />
            </div>
        )}    
             />
      </Worker>
      </div>
    </div>
  )
}

export default ViewPDF;
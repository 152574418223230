import React, {useState, useContext, useEffect} from 'react';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown, Table, Modal } from 'react-bootstrap';
import {Helmet} from 'react-helmet-async';
import ReactToPrint from "react-to-print";
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSVLink } from "react-csv";
//import { faEye, faHome, faSearch, faEllipsisH, faEdit } from '@fortawesome/free-solid-svg-icons';
import { ProductContext } from '../../ProductProvider';
import { downloadHeaders } from '../../ProData';
import Preloader from '../../components/Preloader/Preloader';
import RegistrationForm from '../registration/RegistrationForm';
import AcademicInfo from '../registration/AcademicInfo';
import CertificateCopy from '../registration/CertificateCopy';
import MarkListCopy from '../registration/MarkListCopy';
import RecommendationLetter from '../registration/RecommendationLetter';
import FileAttachments from '../registration/FileAttachments';
import { Link } from "react-router-dom";
import DeactivateModal from '../registration/DeactivateModal';
import StaffForm from './StaffForm';

const config = require('../../config.json');
const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export default function StaffSearch() {
  const { loggedUser, generateDate, validateLogin, handleImageError, setAdminDetails, adminDetails } = useContext(ProductContext);
  const [searchTopic, setSearchTopic] = useState('UserID');
  const [searchValue, setSearchValue] = useState('');
  const [batchYear, setBatchYear]= useState([]);
  const [loaded, setLoaded] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const csvLinkEl = React.createRef();
  const componentRef = React.useRef(null);
  const [profileDetails, setProfileDetails]=useState({applicantName:'', fatherName:'', dateOfBirth:'', gender:'Male', nationality:'',
    religiousStatus:'Scholastic', parishDiocese:'', congregationProvince:'', areaCode:'+91',mobileNumber:'', emailID:'', placeOfBirth:'', 
    birthDistrict:'', birthState:'', localAddress:'', localState:'', localPincode:'', localContact:'', perAddress:'', perState:'', perPincode:'',
    perContactNo:'', courseCategory:'Bachealor of Theology', degreeDetails:[], languageDetails:[], courseDetails:[]});
  const [idProof, setIDProof]=useState('');
  const [certProofFile, setCertProofFile]=useState('');
  const [markListFile, setMarkListFile]=useState('');
  const [recommenLetterFile, setRecommenLetterFile]=useState('');
  const [collapsed, setCollapsed] = useState(true);
  const [newCollapsed, setNewCollapsed] = useState(true);
  const [deactivateCollapsed, setDeactivateCollapsed] = useState(true);
  const componentModelRef = React.useRef(null);
  const [markCollapsed, setMarkCollapsed] = useState(true);
  const [courseDetails, setCourseDetails]=useState({courseCode:'', courseName:''});
  const [formType, setFormType] = useState("View");
  const { enqueueSnackbar } = useSnackbar();
  const [imageFile, setImageFile] = useState('');

  useEffect(()=>{
    if(loggedUser.isAuthenticated){
      //processBatchYear();
    }else{
    validateLogin();
    }
  },[loggedUser])

  const processBatchYear=()=>{
    const current_datetime = new Date();
    const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
    //const lastTenYear=date_year-10;
    var tbatchYear=[];
    for(var i=0; i<10;i++){
      tbatchYear.push(date_year-i);
    }

  //  console.log("batch year : ", tbatchYear);
    setBatchYear(tbatchYear);
    setSearchValue(date_year);
  }

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return <Button variant="outline-primary"  size="sm">Print</Button>;
  }, []);

  const reactToPrintContentModal = React.useCallback(() => {
    return componentModelRef.current;
  }, [componentModelRef.current]);

  const reactToPrintTriggerModal = React.useCallback(() => {
    return <Button   >Print</Button>;
  }, []);


  const handleDownload =async(event)=>{
    event.preventDefault();
    csvLinkEl.current.link.click();
  }

  const toggle = () => {
         setCollapsed(!collapsed);
  }

  const newToggle = () => {
          setNewCollapsed(!newCollapsed);
    }

  const deactivateToggle=()=>{
    setDeactivateCollapsed(!deactivateCollapsed);
  }

  const handleMarkSheetView =(event, item)=>{
    event.preventDefault();
    setProfileDetails(item);
    markToggle();
  }

    const markToggle = () => {
      //   console.log("clicked");
            setMarkCollapsed(!markCollapsed);
      }
  
  const handleSearch=async()=>{
    //event.preventDefault();
    try{
      if(searchValue===""){
        enqueueSnackbar("Search Value is empty Enter the search input and then hit Search button!",{variant:'warning'}); 
      }else{
        setLoaded(false);
        const { idToken } = (await fetchAuthSession()).tokens ?? {};
        //const searchOption="UserID";
        const clientID=loggedUser.website;

      //await axios.get(`${config.api.invokeURL}/dashboard/student/search?fetchKey=${searchValue}&fetchOption=${searchTopic}`, {
        await axios.get(`${config.api.invokeURL}/dashboard/officeform?fetchKey=${searchValue}&fetchOption=${searchTopic}&clientID=${clientID}`, {
        headers: {
          Authorization: idToken,
          'x-api-key':config.api.key
        }
      }).then(async(response) => {
       //console.log("response : ", response.data[0]);
        if(response.data.length>0)
        {
            setSearchResults(response.data);
        }
        enqueueSnackbar(response.data.length + " - student's records found!",{variant:'success'}); 
        setLoaded(true);
    }).catch((error)=>{
      enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
    setLoaded(true);
  })
}
    }catch(error){
      enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
    setLoaded(true);
    }
  }

  const handleApplicationView=async (event, item)=>{
    event.preventDefault();
    try{
      //console.log("Item : ", item);
      setAdminDetails(item);
      //setProfileDetails(item);
        toggle();
    }catch(error){
      enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
    setLoaded(true);
    }

  }

  const handleApplicationEdit=(event, item)=>{
    event.preventDefault();
    try{
        setAdminDetails(item);
      newToggle();
    }catch(error){
      //console.log("Error : ", error);
      enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
    setLoaded(true);
    }

  }

  const postOnS3 = async(applicationno,timageFile,inputType)=>{
    try{
        //  console.log("NewImage : ", newImage);
        var tKeyName="";
        var newFileName="";
        if(inputType==="Profile"){
          tKeyName="/avatar/"+applicationno;   
          newFileName=applicationno+".jpeg";
        }else if (inputType==="Recomm"){
          tKeyName="/avatar/"+applicationno+"/docs";  
          newFileName=applicationno+"_recommLetter.jpeg"; 
        }else if (inputType==="CERTProof"){
          tKeyName="/avatar/"+applicationno+"/docs";  
          newFileName=applicationno+"_certproof.jpeg"; 
        }else if (inputType==="MarkList"){
          tKeyName="/avatar/"+applicationno+"/docs";  
          newFileName=applicationno+"_markproof.jpeg"; 
        }   
      
      //console.log("newfilename :", newFileName);
     // const newImage="";
      var file = timageFile;
      var blob = file.slice(0, file.size, 'image/jpeg'); 
      const newFile = new File([blob], newFileName, {type: 'image/jpeg'});
     // console.log("New file : ", newFile);
      //const session = await Auth.currentSession();
      //const access_token=session.idToken.jwtToken;
      const { idToken } = (await fetchAuthSession()).tokens ?? {};
      const bucketName=config.s3.bucketName + tKeyName;
     // await axios.get(`${config.api.invokeUrl}/profile_avatar/presigned/uploader?fileName=${newFileName}&bucketName=${bucketName}`,{
     // await axios.get(`${config.api.invokeURL}/images/gallery/newgallery?fileName=${newFileName}&bucketName=${bucketName}&keyName=${newKeyName}`,{
      await axios.get(`${config.api.invokeURL}/gallery/newgallery?fileName=${newFileName}&bucketName=${bucketName}`,{
           headers:{
               Authorization: idToken,
               'x-api-key': config.api.key
           }
       }).then(async(response)=>{
         //  console.log("Response : ", response);
          const res=await fetch(response.data.uploadURL, { method: 'PUT',
           headers:{
               "Content-Type":"image/jpeg"
             },body: newFile});
           //  console.log("Res : ", res);
             if(res.status===200){
            //  setNewProfileImage(newFileName);
              //  fetchGalleryImages();
                // setProfileImage(newFileName);
                 //var timestamp = new Date().getTime();     
                // var tprofile = document.getElementById("profile");
                 //var imgsrc="";
                 //imgsrc=imgsrc.concat(config.s3.imageURL,newFileName,"?t=",timestamp);     
                 //tprofile.src=`${config.s3.imageURL}${newFileName}`+"?t="+timestamp;
                // tprofile.src=imgsrc;
                //tprofile.src="https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg";
                 //console.log("tprofle : ", tprofile.src);
             }
        }).catch((error)=>{
           enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
       })
    }catch(error){
     enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
    }
  }

  const validateFields=()=>{
    const {applicantName, fatherName, dateOfBirth, gender, district, state, religiousStatus, parishDiocese, 
      congregationProvince, mobileNumber, emailID, placeOfBirth, localAddress, localState, 
      localPincode, nationality, localContact, perAddress, perState, perPincode, perContactNo, courseCategory,
      languageDetails, degreeDetails }=profileDetails;
      var returnValue="passed";
      if(!emailID.match(validRegex)){
          enqueueSnackbar("Email address is not in right format!",{variant:'warning'});
      returnValue="failed";
      }else  if(applicantName===""){
      enqueueSnackbar("Name cannot be empty!",{variant:'warning'}); 
    returnValue="failed";
  }else  if(dateOfBirth===""){
      enqueueSnackbar("Date of Birth cannot be Empty!",{variant:'warning'}); 
  returnValue="failed";
  }else  if(gender===""){
      enqueueSnackbar("Gender cannot be Empty!",{variant:'warning'}); 
  returnValue="failed";
  }else  if(religiousStatus===""){
      enqueueSnackbar("Religious Status cannot be Empty!",{variant:'warning'}); 
  returnValue="failed";
  }else  if(fatherName===""){
      enqueueSnackbar("Father Name cannot be Empty!",{variant:'warning'}); 
  returnValue="failed";
  }else  if(district===""){
      enqueueSnackbar("District cannot be Empty!",{variant:'warning'}); 
  returnValue="failed";
  }else  if(mobileNumber===""){
      enqueueSnackbar("Mobile Number cannot be Empty!",{variant:'warning'}); 
  returnValue="failed";
  }else  if(emailID===""){
      enqueueSnackbar("Email Address cannot be Empty!",{variant:'warning'}); 
  returnValue="failed";
  }else  if(state===""){
      enqueueSnackbar("State cannot be Empty!",{variant:'warning'}); 
  returnValue="failed";
  }else  if(parishDiocese===""){
      enqueueSnackbar("Parish Diocese cannot be Empty!",{variant:'warning'}); 
  returnValue="failed";
  }else  if(congregationProvince===""){
      enqueueSnackbar("Congregation Province cannot be Empty!",{variant:'warning'}); 
  returnValue="failed";
  }else  if(nationality===""){
      enqueueSnackbar("Nationality cannot be Empty!",{variant:'warning'}); 
  returnValue="failed";
  }else  if(placeOfBirth===""){
      enqueueSnackbar("Place Of Birth cannot be Empty!",{variant:'warning'}); 
  returnValue="failed";
  }else  if(languageDetails===""){
      enqueueSnackbar("Language Details cannot be Empty!",{variant:'warning'}); 
  returnValue="failed";
  }else  if(localAddress===""||localState===""||localPincode===""||localContact===""){
      enqueueSnackbar("Local Address cannot be Empty!",{variant:'warning'}); 
  returnValue="failed";
  }else  if(perAddress===""||perState===""||perPincode===""||perContactNo===""){
      enqueueSnackbar("Permanent Address cannot be Empty!",{variant:'warning'}); 
  returnValue="failed";
  }else  if(degreeDetails===""){
      enqueueSnackbar("Degree Details cannot be Empty!",{variant:'warning'}); 
  returnValue="failed";
  }else  {
    returnValue="passed";
  }
  return returnValue;
  }

  const handleSubmit=async(event)=>{
    event.preventDefault();
    try{
    const validationResult=validateFields();
    if(validationResult==="passed"){
      setLoaded(false);
      const {applicantName, fatherName, dateOfBirth, gender, birthDistrict, birthState, religiousStatus, parishDiocese, 
        congregationProvince, areaCode, mobileNumber, emailID, placeOfBirth, localAddress, localState, 
        localPincode, nationality, localContact, perAddress, perState, perPincode, perContactNo, courseCategory,
        languageDetails, degreeDetails, registrationNo, applicationNo }=profileDetails;
        const clientID = "VJCollege";
        const applicationno= applicationNo;
        //const applicationDate=await generateDate();
        //const applicationStatus="Pending Approval";
        const avatar= applicationno+".jpeg";
        const recommLetter=applicationno+"_recommLetter.jpeg"; 
        const certproofFile=applicationno+"_certproof.jpeg";  
        const markListFile=applicationno+"_markproof.jpeg";
        //const tcourseStatus="Pending Approval";
        //const tcoursePayment="Pending"; 
        const tdate=await generateDate();
        const { idToken } = (await fetchAuthSession()).tokens ?? {};
        const params={
          clientID:clientID, applicationNo: applicationno, registrationNo:registrationNo,
          applicantName:applicantName,fatherName:fatherName, birthDistrict:birthDistrict, birthState:birthState, 
          dateOfBirth:dateOfBirth, gender:gender, religiousStatus:religiousStatus,
          parishDiocese:parishDiocese, congregationProvince:congregationProvince, placeOfBirth:placeOfBirth, 
          localAddress:localAddress, areaCode:areaCode, mobileNumber:mobileNumber, emailID:emailID, localState:localState, 
          localPincode:localPincode, localContact:localContact,
          perAddress:perAddress, nationality:nationality, perState:perState, perPincode:perPincode, 
          perContactNo:perContactNo, avatar:avatar, recommLetter:recommLetter, markListFile:markListFile,
          certproofFile:certproofFile, degreeDetails:degreeDetails, languageDetails:languageDetails,
          updatedBy:loggedUser.name, updatedOn:tdate};
      //console.log("Params : ", params);
      await axios.patch(`${config.api.invokeURL}/dashboard/registration/${applicationno}`, params,{
        headers: {
          Authorization: idToken,
          'x-api-key':config.api.key
        }})
        .then(async(response)=>{
          console.log("response : ", response); 
       if(imageFile){
          await postOnS3(applicationno, imageFile,"Profile");
       }
       if(recommenLetterFile){
          await postOnS3(applicationno, recommenLetterFile,"Recomm");
       }
       if(certProofFile){
          await postOnS3(applicationno, certProofFile,"CERTProof");
       }
       if(markListFile){
        await postOnS3(applicationno, markListFile,"MarkList");
       }
       await handleSearch();
         enqueueSnackbar(applicationno + " - Successfully Updated.",{variant:'success'}); 
        window.scrollTo(0,0);
        newToggle();
        setLoaded(true);
        }).catch((error)=>{
          console.log("Error :", error);
          enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
          window.scrollTo(0,0);
          setLoaded(true);
        })
    }
      }catch(error){
    enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
    window.scrollTo(0,0);
    setLoaded(true);
    }
  }

  const handleDeactivate=async(event,item)=>{
    event.preventDefault();
    setProfileDetails(item);
    deactivateToggle();
  }
 

  return (
    <div>
    {!loaded && <Preloader show={loaded ? false : true} />}
    {/*View*/}
    <div >
      <Modal onHide={toggle} show={!collapsed} size="lg" >
      <div  ref={componentModelRef}>
          <Modal.Header  toggle={toggle}>
            Staff Form - View
                </Modal.Header>
                <Modal.Body >    
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
      <div className="d-block mb-4 mb-md-0">
      </div>
      <div className="btn-toolbar mb-2 mb-md-0"> 
        <ButtonGroup>
        <ReactToPrint
      content={reactToPrintContent}
      documentTitle="student"
      trigger={reactToPrintTrigger}
    />
        {/*<Button variant="outline-primary" size="sm" onClick={(event)=>{handleDownload(event)}}>Download</Button>
        <CSVLink
            headers={downloadHeaders}
            filename="download.csv"
            data={searchResults}
            ref={csvLinkEl}
  />*/}
        </ButtonGroup>
      </div>
    </div>
    <div ref={componentRef} style={{width:"100%", paddingLeft:"20px"}} >
                    <StaffForm  formType={"View"}/> 
                    </div>
                </Modal.Body>
                <Modal.Footer>
        {' '}
        <ReactToPrint
    content={reactToPrintContentModal}
    documentTitle="AwesomeFileName"
    
    trigger={reactToPrintTriggerModal}
  />
        <Button onClick={toggle}>
            Close
        </Button>
        </Modal.Footer>
      </div>    
    </Modal>
    </div>

    {/*Edit*/}
    <Modal onHide={newToggle} show={!newCollapsed} size="lg">
                <Modal.Header toggle={newToggle}>
                Staff Form - Edit
                </Modal.Header>
                <Modal.Body>    
                <div>
                <StaffForm  formType={"Edit"} searchResults={searchResults}/>                     
                  </div>
                </Modal.Body>
                <Modal.Footer>
        {' '}
        <Button onClick={newToggle}>
            Close
        </Button>
        </Modal.Footer>
    </Modal>

    {/*DeActivate*/}
     <Modal show={!deactivateCollapsed} onHide={deactivateToggle}>
                    <Modal.Header toggle={deactivateToggle}>
                    De-Activate - {profileDetails.registrationNo}
                    </Modal.Header>
                    <Modal.Body>    
                    <div className="text-center">
                      <DeactivateModal profileDetails={profileDetails} deactivateToggle={deactivateToggle}/>
                    </div>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button onClick={deactivateToggle}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>
  

        <Breadcrumb>
          <Breadcrumb.Item> <Link to="/">
      <i className="fa fa-home" aria-hidden="true"></i>
      </Link></Breadcrumb.Item>
          <Breadcrumb.Item>Staff</Breadcrumb.Item>
          <Breadcrumb.Item active>Search</Breadcrumb.Item>
        </Breadcrumb>
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
      <div className="d-block mb-4 mb-md-0">

        <h4>Staff Details</h4>
      </div>
      <div className="btn-toolbar mb-2 mb-md-0"> 
        <ButtonGroup>
        <ReactToPrint
      content={reactToPrintContent}
      documentTitle="student"
      trigger={reactToPrintTrigger}
    />
        <Button variant="outline-primary" size="sm" onClick={(event)=>{handleDownload(event)}}>Download</Button>
        <CSVLink
            headers={downloadHeaders}
            filename="download.csv"
            data={searchResults}
            ref={csvLinkEl}
          />
        </ButtonGroup>
      </div>
    </div>
    <div className="table-settings mb-4">
      <Row className="justify-content-start align-items-center">
        <Col lg={4} className="mb-3">
          <Form.Group id="searchstud">               
            <Form.Select defaultValue="Batch" value={searchTopic} 
            size="lg" style={{fontSize:16, borderRadius:10, padding:2, width:"100%"}}
            onChange={(event)=>{setSearchTopic(event.target.value)}}>
            <option value="UserID">User ID</option>
            <option value="UserName">Name</option>
            <option value="EmailID">Email Address</option>
            <option value="UserAccount">User Account</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col  className="mb-3" lg={6}>
          <InputGroup>
            {/*<InputGroup.Text>
              <FontAwesomeIcon icon={faSearch} />
            </InputGroup.Text>*/}
            {searchTopic==="UserAccount"?           
            <Form.Select value={searchValue} 
            size="lg" style={{fontSize:16, borderRadius:10, padding:2, width:"100%"}}
                onChange={(event)=>{setSearchValue(event.target.value)}}>
                  <option key="Residence" value="Residence">Residence</option>
                  <option key="College" value="College">College</option>
          </Form.Select>:
          <Form.Control type="text" placeholder="Search" value={searchValue} onChange={(event)=>{setSearchValue(event.target.value)}}/>}
          </InputGroup>
        </Col>
        <Col  className="mb-3" lg={2}>
            <Button variant="primary" type="submit" onClick={(event)=>{handleSearch()}}>Submit</Button>
          </Col>

      </Row>
    </div>

    <Table ref={componentRef} hover responsive>
          <thead>
              <tr>
              <th>#</th>
              <th>Staff ID</th>
              <th>Name</th>
              <th>Phone</th>
              <th>Email ID</th>
              <th>Status</th>
              <th>Action</th>
              </tr>
          </thead>
          <tbody>
          {searchResults.map((item, index)=>
          <tr key={index}>
          <th scope="row">{index+1}</th>
          <td>{item.userID}</td>
          <td><img id="profile" src={`${config.s3.imageURL}${"avatars/admins/"}${item.userID}${".jpeg"}`} 
          onError={handleImageError}
          alt="Profile" style={{width:"3rem", height:"3rem", borderRadius:"50%"}} /> {item.userName}</td>
          <td>{item.areaCode}-{item.userPhoneNo}</td>
          <td>{item.userEmailID}</td>
          <td>{item.userStatus==="Active"?<p style={{backgroundColor:"green", borderRadius:"10px", textAlign:"center", color:"white"}}>Active</p>:
          <p style={{backgroundColor:"blue", borderRadius:"10px", textAlign:"center", color:"white"}}>{item.userStatus}</p>}</td>
          <td>
          <Dropdown as={ButtonGroup}>
          <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
            <span className="icon icon-sm">
            <i className="fa fa-ellipsis-h icon-dark" aria-hidden="true"></i>

              {/*<FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />*/}
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item  onClick={(event)=>{handleApplicationView(event, item)}}>
            <i className="fa fa-eye" aria-hidden="true">View Details</i>
            </Dropdown.Item>
           {/*} <Dropdown.Item  onClick={(event)=>{handleApplicationEdit(event, item)}}>
            <i className="fa fa-pencil" aria-hidden="true">Edit Details</i>
            </Dropdown.Item>
            <Dropdown.Item  onClick={(event)=>{handleDeactivate(event, item)}}>
            <i className="fa fa-ban" aria-hidden="true"></i>De-Activate
            </Dropdown.Item>*/}
          </Dropdown.Menu>
        </Dropdown>
          </td>
          </tr>)}
          </tbody>
    </Table>
  </div>
  )
}
